/**
 * Provides the main entry point for the Vue frontend.
 * @version 3.0.0.A_413_beed83a_2023-11-29_16:05:09
 */
import Introduction from "@/composables/Introduction";

// Loading Vue
const Vue = require('vue');
const VueRouter = require('vue-router');

// Fetching Vue imports that are needed in Vue.
import PrimeVue from "primevue/config";
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from "primevue/toastservice";
import CurrentUserService from "@/services/CurrentUserService";
import { AppState } from '@/state/AppState';

// Importing the main front end application and the navigation for the application.
import App from './App';
import AppNavigation from "@/views/system/AppNavigation";

// Setting up the current user service.
const currentUserService = new CurrentUserService();

// Setting the components, via lazy loading, for the various routes within the front end application.
const MainView = () => Promise.resolve(import('./views/MainView.vue'));
const ManageUsers = () => Promise.resolve(import('./views/ManageUsers.vue'));
const ManageUserGroups = () => Promise.resolve(import('./views/ManageUserGroups.vue'));
const RegistrationStats = () => Promise.resolve(import('./views/RegistrationStats.vue'));
const ManageRegistrations = () => Promise.resolve(import('./views/ManageRegistrations.vue'));
const ManageRegistration = () => Promise.resolve(import('./views/ManageRegistration.vue'));
const ManageMassEmail = () => Promise.resolve(import('./views/ManageMassEmail.vue'));
const SendMassEmail = () => Promise.resolve(import('./views/SendMassEmail.vue'));
const SetDates = () => Promise.resolve(import('./views/SetDates.vue'));
const NotFound = () => Promise.resolve(import('./views/system/NotFound.vue'));
const ServerError = () => Promise.resolve(import('./views/system/ServerError.vue'));

// Defining the routes to be used for the front end.
const routes = [
    {path: '/', name: 'Home', component: MainView},
    {path: '/ManageUsers', name: 'ManageUsers', component: ManageUsers},
    {path: '/ManageUserGroups', name: 'ManageUserGroups', component: ManageUserGroups},
    {path: '/RegistrationStats', name: 'RegistrationStats', component: RegistrationStats},
    {path: '/ManageRegistrations', name: 'ManageRegistrations', component: ManageRegistrations},
    {path: '/ManageRegistration/:id', name: 'ManageRegistration', component: ManageRegistration},
    {path: '/ManageMassEmail', name: 'ManageMassEmail', component: ManageMassEmail},
    {path: '/SendMassEmail', name: 'SendMassEmail', component: SendMassEmail},
    {path: '/SetDates', name: 'SetDates', component: SetDates},
    {path: "/serverError", name: "ServerError", component: ServerError},
    // Catch all for displaying 404 for a route not defined.
    {path: "/:pathMatch(.*)*", name: "NotFound" , component: NotFound},
];

// Setting up Vue router.
const Router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes
});

// Establishing the main Vue application.
const MainApp = Vue.createApp(App);
MainApp.use(Router);
MainApp.use(PrimeVue);
MainApp.use(ConfirmationService);
MainApp.use(ToastService);

// Establishing the application's Vue app navigation.
const NavApp = Vue.createApp(AppNavigation);
NavApp.use(Router);

// Method for officially launching Vue on the front end.
const initializeApp = () => {
    MainApp.mount('#app');
    NavApp.mount('#vt_main_nav');
};

// Fetching the current user from the API.
// Need to ensure that the current user is returned from the server until
currentUserService.readCurrentUser().then((response) => {

    // Checking for a csrf token returned from the server.
    if (response.headers['x-csrf-token']) {
        AppState.csrf.set(response.headers['x-csrf-token']);
    } else {
        console.warn('CSRF was not provided from server.');
    }

    // Setting the current user in the application state.
    AppState.currentUser.set(response.data);

    // Initializing the front end.
    initializeApp();
}).catch((error) => {
    console.error(error);
    // The server threw an error while attempting to fetch the current user of the application.
    Router.push('/serverError').then(initializeApp);
});

new Introduction();