/**
* Provides the navigation component
* @version 3.0.0.A_404_8020e45_2023-11-21_15:11:33
*/

<template>
  <li class="nav-item">
    <div class="link-wrapper">
      <router-link to="/" v-on:click="closeNavigation">Register</router-link>
    </div>
  </li>
  <li v-if="!this.AppState.currentUser.isPrivilegedUser()" class="nav-item">
    <div class="link-wrapper">
      <a :href="this.baseUrl + 'Login'" title="Administration login" v-on:click="closeNavigation">Administration
        login</a>
    </div>
  </li>
  <li v-if="this.AppState.currentUser.isPrivilegedUser()" class="nav-item">
    <div class="link-wrapper">
      <router-link to="/RegistrationStats" v-on:click="closeNavigation">Registration stats</router-link>
    </div>
  </li>
  <li v-if="this.AppState.currentUser.hasPermission('admin.registrations.manage')" class="nav-item">
    <div class="link-wrapper">
      <router-link to="/ManageRegistrations" v-on:click="closeNavigation">Manage registrations</router-link>
    </div>
  </li>
  <li v-if="this.AppState.currentUser.hasPermission('admin.email.mass.manage')" class="nav-item">
    <div class="link-wrapper">
      <router-link to="/ManageMassEmail" v-on:click="closeNavigation">Manage mass email</router-link>
    </div>
  </li>
  <li v-if="this.AppState.currentUser.hasPermission('admin.email.mass.send')" class="nav-item">
    <div class="link-wrapper">
      <router-link to="/SendMassEmail" v-on:click="closeNavigation">Send mass email</router-link>
    </div>
  </li>
  <li v-if="this.AppState.currentUser.hasPermission('admin.dates.set')" class="nav-item">
    <div class="link-wrapper">
      <router-link to="/SetDates" v-on:click="closeNavigation">Set dates</router-link>
    </div>
  </li>
  <li v-if="this.AppState.currentUser.hasPermission('admin.users.manage')" class="nav-item">
    <div class="link-wrapper">
      <router-link to="/ManageUsers" v-on:click="closeNavigation">Manage users</router-link>
    </div>
  </li>
  <li v-if="this.AppState.currentUser.hasPermission('admin.users.manage')" class="nav-item">
    <div class="link-wrapper">
      <router-link to="/ManageUserGroups" v-on:click="closeNavigation">Manage user groups</router-link>
    </div>
  </li>
  <li v-if="this.AppState.currentUser.isPrivilegedUser()" class="nav-item">
    <div class="link-wrapper">
      <a :href="this.baseUrl + 'Logout'" title="Logout" v-on:click="closeNavigation">Logout</a>
    </div>
  </li>
</template>

<script>
import {AppState} from "@/state/AppState";

export default {
  name: 'AppNavigation',

  /* Created */
  created() {
  },

  /* computed */
  computed: {
    baseUrl() {
      return AppState.baseUrl.get()
    }
  },

  /**
   * Sets the data properties for this component.
   * @returns {Object}
   */
  data() {
    return {
      AppState,
    };
  },
  methods: {
    closeNavigation() {
      window.navToggle();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
