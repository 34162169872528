/**
 * Provides the AJAX service for use on the front end.
 * @version 3.0.0.A_398_9cdb4c8_2023-11-17_15:58:23
 */

// Imports
import axios from "axios";
import AppException from "@/exceptions/AppException";
import {AppState} from "@/state/AppState";

// Defines the method post.
const METHOD_POST = 1;
// Defines the method get.
const METHOD_GET = 2;
// Defines the method put.
const METHOD_PUT = 3;
// Defines the method delete.
const METHOD_DELETE = 4;
// Exception code for invalid method.
const INVALID_METHOD = 1;

/**
 * Handles AJAX requests from the front end to the backend of the application.
 */
export default class AjaxService {

    // Provides the default Axios configurations for this service.
    axiosConfigs = {};

    /**
     * Constructor for the form submission service.
     * @constructor
     */
    constructor() {
        // Setting the required headers for communication with CI4.
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        // Assigning the App state to an internal variable for use later.
        this.appState = AppState;
    }

    /**
     * Returns the POST method constant set in this file.
     * @returns {number} The POST method constant.
     * @constructor
     */
    static get POST() {
        return METHOD_POST;
    }

    /**
     * Returns the GET method constant set in this file.
     * @returns {number} The GET method constant.
     * @constructor
     */
    static get GET() {
        return METHOD_GET;
    }

    /**
     * Returns the PUT method constant set in this file.
     * @returns {number} The PUT method constant.
     * @constructor
     */
    static get PUT() {
        return METHOD_PUT;
    }

    /**
     * Returns the DELETE method constant set in this file.
     * @returns {number} The DELETE method constant.
     * @constructor
     */
    static get DELETE() {
        return METHOD_DELETE;
    }

    /**
     * Returns the provided URL with any trailing slashes removed.
     * @param url The URL.
     * @returns {*} The sanitized URL.
     */
    sanitizeUrl(url) {
        return url.replace(/\/$/, '');
    }

    /**
     * Returns the specified URI with the SA Vue env app base URL prepended.
     * @param uri The URI.
     * @returns {*} The full URL.
     */
    prependAppBaseUrl(uri) {
        return this.appState.baseUrl.get() + uri;
    }

    /**
     * Performs a check to determine if the CSRF token has been provided from the backend.
     */
    checkCSRF() {
        if (!this.appState.csrf.csrfToken) {
            throw new AppException("CSRF cookie name not present in AppState.");
        }
    }

    /**
     * Sets the X-CSRF-TOKEN header in the outgoing AJAX request which is needed for POST, PUT, and DELETE methods.
     */
    setCSRFTokenHeader() {
        this.checkCSRF();
        axios.defaults.headers.common['X-CSRF-TOKEN'] = this.appState.csrf.get();
    }

    /**
     * Updates the CSRF token in the AppState.
     * @param token The token.
     */
    updateCSRFToken(token) {
        this.appState.csrf.set(token);
    }

    /**
     * Checks the provided headers from an AJAX response and processes any important information.
     * @param headers The headers.
     */
    headersCheck(headers) {
        if (headers['x-csrf-token']) {
            this.updateCSRFToken(headers['x-csrf-token']);
        }
    }

    /**
     * Removes the X-CSRF-TOKEN setting from Axios.
     */
    reset() {
        delete axios.defaults.headers.common['X-CSRF-TOKEN'];
    }

    // noinspection JSValidateJSDoc
    /**
     * Executes the AJAX request specified.
     * @param url The URL of the API endpoint to send the request.
     * @param method The method to be used.
     * @param payload The payload to send to the API endpoint.
     * @param config Any configurations provided for the request.
     * @returns {Promise<AxiosResponse<any>>}
     */
    async send(url, method, payload, config = null) {
        // Ensuring the config parameter is an object if no config is given.
        // Combining the provided config with the default axios configs.
        config = {...this.axiosConfigs, ...config || {}};

        // Sanitizing the provided URL which will remove any trailing slashes from the URL.
        // This method must be called on the provided URLs as CI4 will trigger a 301 redirect
        // on any URLs that have a trailing slash.
        url = this.sanitizeUrl(url);

        // Determining what method to be used.
        switch (method) {

            // Executing a POST method to the server.
            case METHOD_POST:
                // Setting the out going CSRF token header.
                this.setCSRFTokenHeader();
                // Executing the server call.
                return await axios
                    .post(url, payload, config)
                    .then((response) => {
                        // The server has responded with a 200 response.
                        // Resetting everything for the next AJAX call.
                        this.reset();
                        // Checking the response header for a new CSRF token or any other data
                        // that should be parsed and processed.
                        this.headersCheck(response.headers);
                        // Returning the response to the calling method.
                        return response;
                    })
                    // The server returned a non 200 response.
                    .catch((error) => {
                        // Resetting the http request headers to remove the csrf token.
                        this.reset();
                        // Resetting the CSRF token in app state for the next request.
                        this.headersCheck(error.response.headers);
                        throw new AppException("Payload could not be delivered.", error);
                    });
            // Executing a GET method to the server.
            case METHOD_GET:
                // Executing the server call.
                return await axios
                    .get(url, config)
                    .then((response) => {
                        // The server has responded with a 200 response.
                        // Checking the response header for a new CSRF token or any other data
                        // that should be parsed and processed.
                        this.headersCheck(response.headers);
                        // Returning the response to the calling method.
                        return response;
                    })
                    // The server returned a non 200 response.
                    .catch((error) => {
                        throw new AppException("Payload could not be delivered.", error);
                    });
            // Executing a PUT method to the server.
            case METHOD_PUT:
                // Setting the out going CSRF token header.
                this.setCSRFTokenHeader();
                // Executing the server call.
                return await axios
                    .put(url, payload, config)
                    .then((response) => {
                        // The server has responded with a 200 response.
                        // Resetting everything for the next AJAX call.
                        this.reset();
                        // Checking the response header for a new CSRF token or any other data
                        // that should be parsed and processed.
                        this.headersCheck(response.headers);
                        // Returning the response to the calling method.
                        return response;
                    })
                    // The server returned a non 200 response.
                    .catch((error) => {
                        // Resetting the http request headers to remove the csrf token.
                        this.reset();
                        // Resetting the CSRF token in app state for the next request.
                        this.headersCheck(error.response.headers);
                        throw new AppException("Payload could not be delivered.", error);
                    });
            // Executing a PUT method to the server.
            case METHOD_DELETE:
                // Setting the out going CSRF token header.
                this.setCSRFTokenHeader();
                // Executing a DELETE method to the server.
                return await axios
                    .delete(url, config)
                    .then((response) => {
                        // The server has responded with a 200 response.
                        // Resetting everything for the next AJAX call.
                        this.reset();
                        // Checking the response header for a new CSRF token or any other data
                        // that should be parsed and processed.
                        this.headersCheck(response.headers);
                        // Returning the response to the calling method.
                        return response;
                    })
                    // Executing a PUT method to the server.
                    .catch((error) => {
                        // Resetting the http request headers to remove the csrf token.
                        this.reset();
                        // Resetting the CSRF token in app state for the next request.
                        this.headersCheck(error.response.headers);
                        throw new AppException("Payload could not be delivered.", error);
                    });
            // An invalid method was called.
            default:
                throw new AppException("Invalid method.", INVALID_METHOD);
        }
    }
}