/**
 * Provides the console message.
 * @version 3.0.0.A_376_e58d594_2023-11-09_12:25:28
 */

/**
 * Console introduction
 */
export default class Introduction {
    constructor() {
        let reportIssue = 'https://forms.office.com/r/m68H444aMm';
        console.info(`%cRun\n`
            + `%cDoes this application need fixes or improvements? ${reportIssue}\n`
            + `© Virginia Tech, All Rights Reserved\n`
            , `font-size: 3em; padding-bottom: 0.5em`, 'padding-bottom: 0.5em');
    }
}