/**
* Provides the main app view using the Vue-Router.
* @author Joseph Pahl - phanku@vt.edu
* @version 3.0.0.RC1_434_5bdb972_2023-12-08_12:28:52
*/

<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  created() {
    // Clearing the local storage to ensure the application has the latest data.
    localStorage.clear();
  }
}
</script>

<style lang="scss">
@import 'primevue/resources/primevue.min.css';
@import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css';
@import 'primeicons/primeicons.css';
@import '../../CIDSAF/frontend/src/assets/css/primevue-vt-theme.css';
@import './assets/css/application-theme.css';
</style>
