/**
 * Provides the application wide state management object.
 * This AppState stores the current user object.
 * @version 3.0.0.A_398_9cdb4c8_2023-11-17_15:58:23
 */

import {reactive} from 'vue';

/**
 * The application wide state management.
 */
export const AppState = reactive({
    baseUrl: {
        // Note this variable is automatically set during the build process.
        url: process.env.BASE_URL,
        // Determines if the base url has been parsed to remove the `dist` directory in the URL.
        isProcessed: false,
        // Returns the base URL of the application.
        get() {
            // Short circuit for the method.
            // Return the URL as it is stands if the `isProcessed` flag is true.
            if (this.isProcessed) {
                return this.url;
            }

            // Ensuring there is no trailing slash on the URL for later processing.
            let url = this.url.endsWith('/') ? this.url.slice(0, -1) : this.url;
            // Removing the last section of the URL via the slash deliminator.
            url = url.substring(0, url.lastIndexOf('/'));
            // Ensuring the out going URL has a trailing slash.
            url += url.endsWith('/') ? '' : '/';
            // Saving the parsed URL.
            this.url = url;
            // Switching the `isProcessed` flag to true.
            this.isProcessed = true;
            // Returning the URL.
            return this.url;
        }
    },
    csrf: {
        csrfToken: null,
        set(token) {
            this.csrfToken = token;
        },
        get() {
            return this.csrfToken;
        }
    },
    currentUser: {
        user: {
            userEntity: null,
            permissions: [],
            isPrivilegedUser: false,
        },
        isPrivilegedUser() {
            return this.user.isPrivilegedUser;
        },
        /**
         * Sets the current user fetched from the server.
         * @param currentUser
         */
        set(currentUser) {
            // Setting the current user.
            this.user = currentUser;
        },
        get() {
            return this.user;
        },
        /**
         * Returns the user entity for the current user.
         */
        getUserEntity() {
            if (!this.user.userEntity) {
                throw new Error("User entity is not established.");
            }
            return this.user.userEntity;
        },
        /**
         * Returns the value of the specified property from the current user's user entity.
         * @param property The property.
         * @returns {*} The value of the property.
         */
        getUserEntityProperty(property) {
            let userEntity = this.getUserEntity();
            if (property in userEntity) {
                return userEntity[property];
            }
            throw new Error("Property does not exist.");
        },
        /**
         * Returns true if, and only if, the current user has the specified permission.
         * @param permission The permission.
         * @returns {boolean|*}
         */
        hasPermission(permission) {
            if (this.user.permissions.length === 0) {
                return false;
            }
            return this.user.permissions.includes(permission);
        },
        /**
         * Returns true if, and only if, the current user has all the specified permissions.
         * @param permissions An array of permissions.
         * @returns {boolean} True if the user has all the specified permissions.
         */
        hasPermissions(permissions) {
            if (!Array.isArray(permissions)) {
                return false;
            }
            return permissions.filter(permission => !this.hasPermission(permission)).length === 0;
        },
        /**
         * Returns true if, and only if, the current user has at least one of the specified permissions.
         * @param permissions An array of permissions.
         * @returns {boolean} True if the user has at least one of the specified permissions.
         */
        hasOneOfPermissions(permissions) {
            if (!Array.isArray(permissions)) {
                return false;
            }
            return permissions.filter(permission => this.hasPermission(permission)).length !== 0;
        }
    }
});