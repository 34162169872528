export default class AppException {

    message = '';
    code = 0;
    error = null;

    /**
     * Constructor for the form submission service.
     * @constructor
     */
    constructor(message, error, code) {
        this.message = message;
        this.code = code;
        this.error = error;
    }
}