/**
 * Provides the services needed for communicating with the current user API endpoint.
 * @version 3.0.0.A_398_9cdb4c8_2023-11-17_15:58:23
 */

// Imports
import AjaxService from "@/services/AjaxService";

// The API end point for this service.
// Note: The URLs on linux servers are case-sensitive.
const uri = 'Api/NonRestricted/CurrentUser/index/';

/**
 * Current user service
 */
export default class CurrentUserService extends AjaxService {

    // Stores the compiled URL for the end point.
    calculatedURL = '';

    /**
     * Constructor
     */
    constructor() {
        super();
        // Calculating the full URL for the API end point.
        this.calculatedURL = this.prependAppBaseUrl(uri);
    }

    /**
     * Executes an AJAX call to fetch a user.
     */
    async readCurrentUser() {
        return this.send(this.calculatedURL, AjaxService.GET, null);
    }
}